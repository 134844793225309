import { isEmpty } from "lodash";
import React, { useState, useContext, useRef, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import AuthProvider from "../context/AuthContext";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import styles from "../assets/newCss/sky.css";
import { Helmet } from "react-helmet";
import { ImEye ,ImEyeBlocked  } from "react-icons/im";
function LoginMobile(props) {
  let { loginUser, isLoged, user } = useContext(AuthProvider);
  const navigate = useNavigate();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    unregister,
    reset: reset2,
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
      uniqueId: Math.random() * 10000,
    },
  });

  const [passwordType, setPasswordType] = useState("password");
  const togglePassword =(e)=>{
    e.preventDefault()
    if(passwordType==="password")
    {
     setPasswordType("text")
     return;
    }
    setPasswordType("password")
  }
  const [getValidateCode, setValidateCode] = useState("");
  const [data, setData] = useState({});
  const onSubmit2 = (data) => {
    if (parseInt(data.validateCode) === parseInt(getValidateCode)) {
      loginUser(data);
    }
  };
  const canvasRef = useRef(null);
  const changeCode = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.font = "bold 120px sans-serif";
    const code = Math.floor(1000 + Math.random() * 9000);
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillText(code, 0, 130);
    setValidateCode(code);
  };

  const getSetting = async (event) => {
    try {
      const { status, data: response_users } = await apiGet(apiPath.getSetting);
      if (status === 200) {
        if (response_users.success) {
          setData(response_users.results);
        }
      }
    } catch (err) {}
  };
  useEffect(() => {
    changeCode();
    getSetting();
  }, []);
  useEffect(() => {
    // console.log('%cLogin.js line:43 user', 'color: #007acc;', user);
    if (user !== null) {
      // console.log("MMMM")
      // window.location.reload();
      navigate("/");
    }
  }, [user]);

  return (
    <div className="newLogin">
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1.0, maximum-scale = 1.0, user-scalable = no"
        />
      </Helmet>

      <div className="login_main">
        <header className="login-head">
          {/* <Link to="/" className="close"></Link> */}
          <h1></h1>
          <div id="powerWrap" className="betfair-wrap">
            <p>Powered by</p>
            <span>
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/betfair_black.png"
                }
                alt=""
                draggable="false"
              />
            </span>
          </div>
        </header>
        <Form onSubmit={handleSubmit2(onSubmit2)}>
          <dl className="form-login">
            <Form.Group
              style={{ marginBottom: "10px" }}
              id="loginNameErrorClass"
            >
              <Form.Control
                type="text"
                autocomplete="off"
                // id="loginName"
                className={errors2.username ? "is-invalid" : ""}
                {...register2("username", {
                  required: "Please enter Username",
                })}
                placeholder="Username"
              />
              {errors2.username && errors2?.username?.message && (
                <label className="invalid-feedback text-left">
                  {errors2.username.message}
                </label>
              )}
            </Form.Group>
            <Form.Group className="mb-2">
                    <div style={{ position:'relative'}}>
                      <Form.Control
                         type={passwordType ?passwordType:"password"}
                        placeholder="Password"
                        className={errors2.password ? "is-invalid" : ""}
                        {...register2("password", {
                          required: "Please enter password",
                        })}
                      />
                       <div className="input-group-btn" style={{position:'absolute', right:'0', top:'-5px', bottom:'0px'}}>
                     <span className="btn btn-outline-primary" onClick={togglePassword} style={{color:` #000`,fontSize:`20px`,backgroundColor:'transparent', border:'0',padding:'8px', paddingTop:'3px', height:'72%',filter: `brightness(0.5)`}}>
                     { passwordType==="password"?<ImEyeBlocked /> :  <ImEye/>}
                      </span>
                      </div>
                      </div>
                      {errors2.password && errors2.password.message && (
                        <label className="invalid-feedback text-left">
                          {errors2.password.message}
                        </label>
                      )}
                    </Form.Group>
            <dd id="validCodeErrorClass" style={{ display: "block" }}>
              <input
              style={{height: `39px`}}
                type="number"
                keyboardType="numeric"
                autocomplete="off"
                maxLength="4"
                className={errors2.validateCode ? "is-invalid" : ""}
                {...register2("validateCode", {
                  required: "Please enter validate code",
                  validate: {
                    validate: (value) =>
                      parseInt(value) === parseInt(getValidateCode) ||
                      "Invalid validate code",
                  },
                })}
                onChange={(e) => {
                  if (e.target.value.length == 4) {
                    e.target.blur();
                    unregister("validateCode", { keepValue: true });
                  }
                }}
                // onChange={(e) => {
                //   if (e.target.value.length <= 4) {
                //     setCode(false);
                //   } else {
                //     setCode(true);
                //   }
                // }}
                id="validCode"
                placeholder="Validation Code"
                // onClick={(e) => e.target.blur()}
                // onKeyDown={(e) => e.target.blur()}
              />
              {/* <img
                id="authenticateImage"
                src="assets/images/verifycode.jpg"
                alt=""
              /> */}
              <canvas
                ref={canvasRef}
                onClick={changeCode}
                className="inputcanvas"
                id="authenticateImage"
              />

              {errors2.validateCode && errors2.validateCode.message && (
                <label className="invalid-feedback text-left">
                  {errors2.validateCode.message}
                </label>
              )}
            </dd>
            {/* <dd>
              <input name="valid" type="hidden" id="valid" />
            </dd> */}
            <dd>
              <Button type="submit" className="btn-send w-100" id="loginBtn">
                Login
              </Button>
            </dd>
            {isLoged == "failed" && (
              <dd id="errorMsg" class="state">
                Login name or password is invalid! Please try again.
              </dd>
            )}
          </dl>
        </Form>
        
      
      </div>
    </div>
  );
}

export default LoginMobile;
